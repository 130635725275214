import { NavLink, Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { VscTriangleUp } from "react-icons/vsc";
import { useEffect, useState } from "react";
import homeImg from "../assets/1stle-logo.png";
import { useAuth0 } from "@auth0/auth0-react";
const Nav = ({
	isAdmin,
	wordBankAccess,
	requestAccess,
	templateAccess,
	setTemplateAccess,
	setRequestAccess,
	setWordBankAccess,
	setIsAdmin,
	setToken,
	setUserId,
	isPublicStorage,
	setIsPublicStorage,
	setReportAccess,
	reportAccess,
	hasUnsavedFormData,
	setHasUnsavedFormData,
}) => {
	const navigate = useNavigate();
	const [menuOpen, setMenuOpen] = useState(false);
	const { logout, user } = useAuth0();
	const [showInjurySupport, setShowInjurySupport] = useState(false);

	const logOut = (e) => {
		if (typeof hasUnsavedFormData !== "undefined" && hasUnsavedFormData === true) {
			let confirmationResult = window.confirm("You have unsaved changes, are you sure you want to leave?");
			if (confirmationResult === true) {
				setHasUnsavedFormData(false);
			} else {
				return;
			}
		}
		setIsAdmin(false);
		setToken("");
		setWordBankAccess(false);
		setRequestAccess(false);
		setTemplateAccess(false);
		setUserId(-1);
		setIsPublicStorage(false);
		setReportAccess(false);
		localStorage.removeItem("token");
		localStorage.removeItem("admin");
		localStorage.removeItem("hasWordBankAccess");
		localStorage.removeItem("templateAccess");
		localStorage.removeItem("requestAccess");
		localStorage.removeItem("userId");
		localStorage.removeItem("isPublicStorage");
		localStorage.removeItem("reportAccess");
		localStorage.removeItem("username");
		if (typeof setHasUnsavedFormData !== "undefined") {
			setHasUnsavedFormData(false);
		}
		logout();
		navigate("/login");
	};

	const checkForUnsavedData = (e) => {
		if (typeof hasUnsavedFormData !== "undefined" && hasUnsavedFormData === true) {
			let confirmationResult = window.confirm("You have unsaved changes, are you sure you want to leave?");
			if (confirmationResult === true) {
				setHasUnsavedFormData(false);
			} else {
				e.preventDefault();
			}
		}
	};

	const checkUnsavedAndCloseMenu = (e) => {
		setMenuOpen(false);
		checkForUnsavedData(e);
	};

	if (!user) {
		return;
	}

	return (
		<nav>
			{showInjurySupport ? (
				<div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black/[.7] z-[200]">
					<div className="bg-white p-4 rounded-lg text-center mx-4">
						<p className="text-2xl font-bold  mb-4">Injury Support</p>
						<div className="text-sm md:text-base">
							<p>
								If you need to report a work injury, please first report it to our HR dept at <span className="font-bold">209-601-8771</span> or{" "}
								<a href="mailto:humanresources@1stle.com" className="font-bold underline text-blue-600">
									humanresources@1stle.com
								</a>
								.
							</p>
							<p>Once you have notified our HR dept, please reach out to our 24/7 Nurse Line for WC injuries via phone call to:</p>
							<p className=" font-extrabold text-lg ">1-855-811-5702</p>
							<p>Nurses are standing by to direct you to the right care at the right time.</p>
							<p className="font-bold  text-lg">In the event of a serious emergency, do not wait to speak to a nurse. Call 911.</p>
						</div>

						<button className="bg-gray-500 hover:bg-gray-600 p-3 text-white rounded-lg mt-3" onClick={() => setShowInjurySupport(false)}>
							Close
						</button>
					</div>
				</div>
			) : null}

			<div className="text-center bg-black text-white p-4 flex justify-between md:items-center">
				<NavLink to="/dashboard" onClick={checkForUnsavedData}>
					<img src={homeImg} alt="home" className="w-auto h-12" />
				</NavLink>
				<div className="hidden md:flex md:gap-3 md:items-center">
					<p className="cursor-pointer" onClick={(e) => setShowInjurySupport(true)}>
						Injury Support
					</p>
					{(wordBankAccess || isAdmin) && !isPublicStorage ? (
						<Link to="/wordbank" onClick={checkForUnsavedData}>
							Word Bank
						</Link>
					) : null}
					<Link to="/orders?page=1">Orders</Link>
					{(templateAccess || isAdmin) && !isPublicStorage ? (
						<Link to="/templates?page=1&archived=0&query=&type=all&model=all&portfolio=all" onClick={checkForUnsavedData}>
							Templates
						</Link>
					) : null}
					{user["https://claim.krummycapture/claim"]["clocking_notes_permission"] || user["https://claim.krummycapture/claim"]["admin"] ? (
						<Link to="/clocking-notes" onClick={checkUnsavedAndCloseMenu}>
							Clocking Notes
						</Link>
					) : null}
					{/* {(requestAccess || isAdmin) && !isPublicStorage ? (
						<Link to="/request-list" onClick={checkForUnsavedData}>
							Requests
						</Link>
					) : null} */}
					<Link to="/projects/search?query= &page=1&contract=&stage=&template=&model=project.task" onClick={checkForUnsavedData}>
						Projects
					</Link>
					{(reportAccess || isAdmin) && !isPublicStorage ? (
						<Link to="/reports" onClick={checkForUnsavedData}>
							Reports
						</Link>
					) : null}
					<p className="cursor-pointer" onClick={(e) => logOut(e)}>
						Log out
					</p>
				</div>
				<div className="flex flex-col items-end justify-center md:hidden">
					{menuOpen ? (
						<VscTriangleUp
							onClick={() => setMenuOpen((prev) => !prev)}
							className="text-4xl cursor-pointer border border-white rounded p-1 select-none"
						/>
					) : (
						<GiHamburgerMenu
							onClick={() => setMenuOpen((prev) => !prev)}
							className="text-4xl cursor-pointer border border-white rounded p-1 select-none"
						/>
					)}
				</div>
			</div>
			<div className={`bg-black text-white flex flex-col ${menuOpen ? "pr-4 pb-4" : " "} items-end justify-center md:hidden`}>
				{menuOpen ? (
					<div className="flex flex-col text-left gap-4">
						<p className="cursor-pointer" onClick={(e) => setShowInjurySupport(true)}>
							Injury Support
						</p>
						{(wordBankAccess || isAdmin) && !isPublicStorage ? (
							<Link to="/wordbank" onClick={checkUnsavedAndCloseMenu}>
								Word Bank
							</Link>
						) : null}

						<Link to="/orders?page=1">Orders</Link>
						{(templateAccess || isAdmin) && !isPublicStorage ? (
							<Link to="/templates?page=1&archived=0&query=&type=all&model=all&portfolio=all" onClick={checkUnsavedAndCloseMenu}>
								Templates
							</Link>
						) : null}

						{user["https://claim.krummycapture/claim"]["clocking_notes_permission"] || user["https://claim.krummycapture/claim"]["admin"] ? (
							<Link to="/clocking-notes" onClick={checkUnsavedAndCloseMenu}>
								Clocking
								<br />
								Notes
							</Link>
						) : null}
						{/* {(requestAccess || isAdmin) && !isPublicStorage ? (
							<Link to="/request-list" onClick={checkUnsavedAndCloseMenu}>
								Requests
							</Link>
						) : null} */}
						<Link to="/projects/search?query= &page=1&contract=&stage=&template=&model=project.task" onClick={checkUnsavedAndCloseMenu}>
							Projects
						</Link>
						{(reportAccess || isAdmin) && !isPublicStorage ? (
							<Link to="/reports" onClick={checkUnsavedAndCloseMenu}>
								Reports
							</Link>
						) : null}
						<p className="cursor-pointer" onClick={(e) => logOut(e)}>
							Log out
						</p>
					</div>
				) : null}
			</div>
		</nav>
	);
};

export default Nav;
